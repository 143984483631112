<template>
    <div class="container">
        <nav class="navbar-fixed" :class="{ 'navbarBlack': showSecondRow }">
            <div class="navbar-container">
                <div class="toggle-button-medium" @click="toggleMenuResponsive()">
                    <i class="fas fa-bars"></i> <!-- Icono de barras (toggle) -->
                </div>
                <router-link class="navbar-brand" to="/home">
                    <img :src="getAbsoluteImageUrl('/img/logo/app.png')" alt="Logo de la empresa"
                        class="logo-img img-fluid" :class="showSecondRow ? 'logo-img-smaller' : 'logo-img'">
                </router-link>

                <!-- Botón para colapsar el navbar en pantallas pequeñas -->
                <button class="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="container-fluid">
                    <div class="row align-items-center">
                        <!--<div class="col-lg-1 col-md-1 col-sm-2">
                            <button class="btn btn-success" @click="toggleSidebar"></button>
                        </div>-->

                        <div class="col-lg-2 col-md-2 col-sm-3">
                            <router-link class="btn-toggle" :to="{ name: 'catalogoview' }"
                                style="text-decoration:none;font: monospace;" @click="closeList">Busqueda
                                Avanzada&nbsp;<i class="fa fa-list" aria-hidden="true"></i></router-link>
                            <!--
<button class="btn-toggle" :class="{ 'active': showList }" @click="toggleList"
                                style="font-size: 12px;">Busqueda Avanzada</button>
                            
                            <div v-if="showList" class="category-list" :class="{ 'active': showList }">
                                <div class="column column1">
                                    <div class="category-container">
                                        <h6 style="color: #ff9900;">Años</h6>
                                        <ul style="width: 90%;max-height: 200px; overflow-y: auto;">
                                            <router-link tag="li" v-for="year in lineasBsuqe"
                                                :to="{ name: 'catalogoview', params: { year: year.year } }"
                                                style="text-decoration:none; font-size:0.75rem;color: #fff;font: monospace;"
                                                @click="closeList">
                                                <div>{{ year.year }}</div>
                                            </router-link>
                                        </ul>
                                    </div>
                                </div>
                                <div class="column column2">
                                    <div class="category-container">
                                        <h6 style="color: #ff9900;">Lineas</h6> 
                                        <ul style="width: 100%;max-height: 200px; overflow-y: auto;">
                                            <li v-for="linea in lineasDispo">
                                                <router-link
                                                    :to="{ name: 'catalogoview', params: { year: 0, categoria: linea.id } }"
                                                    style="text-decoration:none; font-size:9px;color: #fff;font: monospace;"
                                                    @click="closeList">{{ linea.name }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            -->
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-6">
                            <!-- Input de búsqueda -->
                            <div class="search">
                                <div class="search-wrapper">
                                    <form @submit.prevent="busquedaEnter">
                                        <input id="inputsearch" type="text" class="search-input"
                                            :class="{ 'expanded': searchExpanded }" placeholder="Buscar..."
                                            style="height: 40px;" @input="handleSearchInputDebounced"
                                            @keyup.enter="busquedaEnter" @blur="hideResults" v-model="inputValue">
                                    </form>
                                    <!-- Icono de borrar texto -->
                                    <!-- Icono de búsqueda -->
                                    <div class="search-icon-container">
                                        <i v-if="!searching" style="color:#333;" class="fa fa-search"
                                            aria-hidden="true"></i>
                                        <div v-else class="spinner-border spinner-border-sm text-dark spinner-icon"
                                            role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="clear-icon" v-if="inputValue.length > 1" @click="clearSearchText">
                                        <i class="fa fa-times-circle" aria-hidden="true"></i>
                                    </div>
                                </div>

                            </div>
                            <div v-if="showCaptcha">
                                <p>Please complete the CAPTCHA to continue</p>
                                <!-- reCAPTCHA v3 se ejecuta en segundo plano, no requiere un elemento visual explícito -->
                            </div>
                            <div v-if="(resultados.length > 0) && menuproductos">
                                <div class="category-list2"
                                    style="background-color: #fff;width: 40%;display: inline-block; display: flex;">
                                    <div class="column" style="width: 30%;flex: 1;" v-if="resultados.length > 0">
                                        <div class="group-header">
                                            <span style="color: #333;font-size:16px;">Sugeridos: </span>
                                            <span>&nbsp;</span>
                                            <span class="badge badge-pill badge-primary"><i
                                                    class="fas fa-tag"></i></span>
                                        </div>
                                        <div v-if="resultados.length > 0">
                                            <ul>
                                                <li v-for="resultado in resultados" :key="resultado.id"
                                                    style="cursor: pointer;">
                                                    <div class="nav-link2"
                                                        @mousedown.prevent="handleResultClick(resultado)">
                                                        <i class="fas fa-search fa-fw"></i>
                                                        <span style="color: #4a92a8; font-size: 12px;">{{
            resultado.k_parte }}</span>
                                                        <span>&nbsp;</span>
                                                        <span class="linea" style="color: #333; font-size: 9px;">{{
            resultado.linea }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-else-if="resultados.length < 1 && inputValue.length > 3">
                                            <span style="color: #4a92a8;font-size:12px;">No encontramos similitudes con
                                                tu
                                                busqueda.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="column" style="width: 30%;flex: 1;" v-if="masbuscados.length > 0">
                                        <div class="group-header">
                                            <span style="color: #333;font-size:16px;">Ultimas Busquedas: </span>
                                            <span>&nbsp;</span>
                                            <span class="badge badge-pill badge-warning"><i
                                                    class="fas fa-search"></i></span>
                                        </div>
                                        <div>
                                            <ul>
                                                <li v-for="buscados in masbuscados">
                                                    <router-link class="nav-link2" @click="ajusteclick"
                                                        :to="{ name: 'ListadoProductos', params: { prod: buscados.consulta } }">
                                                        <span style="color: #EC9704;font-size:12px;">{{
            buscados.consulta
        }}</span>
                                                        <span>&nbsp;</span>
                                                        <span class="linea" style="color: #333;font-size:9px;">{{
                buscados.resultados }}
                                                            Resultados</span>
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="column" style="width: 30%;flex: 1;" v-if="mejoresVentas.length > 0">
                                        <div class="group-header">
                                            <span style="color: #333;font-size:16px;">Más Vendidos: </span>
                                            <span>&nbsp;</span>
                                            <span class="badge badge-pill badge-success"><i
                                                    class="fas fa-shopping-bag"></i></span>
                                        </div>
                                        <div>
                                            <ul>
                                                <li v-for="venta in mejoresVentas">
                                                    <router-link class="nav-link2" @click="ajusteclick"
                                                        :to="{ name: 'verprods', params: { id: venta.k_parte } }">
                                                        <span style="color: #344C11;font-size:12px;">{{ venta.k_parte
                                                            }}</span>
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-3">
                            <!-- Icono de carrito -->
                            <div v-if="isLoggedin && permissions_auth.includes('permission_compras') " class="cart">
                                <div class="cart-top">
                                    <!-- Ícono del carrito y cantidad en carrito -->
                                    <router-link :to="{ name: 'CartItemsList' }" class="cart-link"
                                        style="text-decoration: none;color: inherit;color: #ff9900;">
                                        <i class="bx bx-cart"></i>
                                        <span>&nbsp;</span>
                                        <span class="badge text-bg-secondary"
                                            style="background-color: #06283c;color: #ff9900;">
                                            {{ obtenerCantidadEnCarrito }}
                                        </span>
                                    </router-link>
                                </div>
                                <div class="cart-bottom">
                                    <!-- Cliente concatenado con nombre del cliente -->
                                    <span class="cliente-info" style="color: #ff9900;">
                                        {{ cliente_auth }} 
                                    </span>
                                </div>
                            </div>
                            <div v-else-if="isLoggedin && !permissions_auth.includes('permission_compras')">
                            </div>
                            <div v-else> <!-- Mostrar contenido solo si el usuario no está autenticado -->
                                <!--<router-link :to="getLoginUrl()" class="nav-link-container" @mouseover="showText = true"
                                    @mouseleave="showText = false" style="text-decoration: none;color: inherit;">
                                    Ingresar
                                    <i class="bx bx-log-in-circle" aria-hidden="true"></i>

                                </router-link>-->
                                <div class="icon" @click="getLoginUrl" style="color:#ff9900;">
                                    <span class="menu-text">Ingresar</span><i class="bx bx-log-in-circle"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center navbar-second-row" style="width:100%;height: 1%;"
                        v-show="!showSecondRow">
                        <!-- Contenido de la segunda fila -->

                        <router-link to="/Home" class="nav-link-container"
                            style="text-decoration: none;color: inherit;">
                            <div class="nav-link">
                                <i class="fa fa-home" aria-hidden="true" style="color: #ff9900;"></i>
                                <span>Home</span>
                            </div>
                        </router-link>
                        <router-link to="/ListBlogs" class="nav-link-container"
                            style="text-decoration: none;color: inherit;">
                            <div class="nav-link">
                                <i class="bx bxl-blogger" aria-hidden="true" style="color: #ff9900;"></i>
                                <span>Blog</span>
                            </div>
                        </router-link>

                        <router-link to="/contacto" class="nav-link-container"
                            style="text-decoration: none;color: inherit;">
                            <div class="nav-link">
                                <i class="fa fa-envelope fa-fw" aria-hidden="true" style="color: #ff9900;"></i>
                                <span>Contacto</span>
                            </div>
                        </router-link>


                        <!--<router-link to="/ProdsMX" class="nav-link-container"
                            style="text-decoration: none;color: inherit;font-size:12px;">
                            <div class="nav-link">
                                <i class="fa fa-map fa-fw" aria-hidden="true"></i>
                                <span>Hecho en México</span>
                            </div>
                        </router-link>-->

                        <router-link to="/catalogosview" class="nav-link-container"
                            style="text-decoration: none;color: inherit;">
                            <div class="nav-link">
                                <i class="fa fa-regular fa-book fa-fw" aria-hidden="true" style="color: #ff9900;"></i>
                                <span>Catálogos</span>
                            </div>
                        </router-link>


                        <router-link to="/OurBrands" class="nav-link-container"
                            style="text-decoration: none;color: inherit;">
                            <div class="nav-link">
                                <i class="fa fa-dot-circle fa-fw" aria-hidden="true" style="color: #ff9900;"></i>
                                <span>Nuestras Marcas</span>
                            </div>
                        </router-link>

                    </div>
                </div>
            </div>
            <div class="navbar-tablet">
                <button class="btn-toggle" :class="{ 'navbarBlack': !showSecondRow }" @click="toggleMenu">Busqueda
                    Avanzada</button>
            </div>

            <!-- Navbar para teléfono -->
            <div class="navbar-mobile">
                <div class="toggle-button" @click="toggleMenuResponsive()">
                    <i class="fas fa-bars"></i> <!-- Icono de barras (toggle) -->
                </div>


                <router-link to="/home" class="logo-container">
                    <img :src="getAbsoluteImageUrl('/img/logo/app.png')" alt="Logo de la empresa" class="logo">
                </router-link>
                <div class="icons">
                    <router-link to="/home" tag="div" class="search-icon"
                        style="color: inherit; /* Hereda el color del texto de su contenedor */text-decoration: none; ">
                        <i class="fas fa-home bx-xs"></i>
                    </router-link>
                    <router-link to="/mobilsearch" tag="div" class="search-icon"
                        style="color: inherit; /* Hereda el color del texto de su contenedor */text-decoration: none; ">
                        <i class="fas fa-search bx-xs"></i>
                    </router-link>
                    <router-link v-if="isLoggedin && permissions_auth.includes('permission_compras')"
                        to="/CartItemsList" tag="div" class="login-icon"
                        style="text-decoration: none;color: inherit; display: flex; align-items: center;">
                        <i class="bx bx-cart bx-xs"></i>
                        <span class="badge text-bg-secondary"
                            style="font-size: 10px; margin-left: 5px;margin-right: 0;background-color: #06283c;color: #ff9900;">{{
            obtenerCantidadEnCarrito }}</span>
                    </router-link>
                    <div v-else-if="isLoggedin && !permissions_auth.includes('permission_compras')">
                    </div>
                    <router-link v-else to="/Ingresa" tag="div" class="login-icon"
                        style="text-decoration: none;color: #ff9900;">
                        <i class="fas fa-user bx-xs"></i>
                    </router-link>
                </div>
            </div>
        </nav>
    </div>


    <div class="sidebarRespo" v-if="sidebarOpenRespo">
        <!-- Contenido del sidebar -->
        <button class="btn btn-dark" @click="toggleSidebarRespo"><i class="bx bx-window-close"></i></button>
        <!-- Agrega aquí más contenido si es necesario -->
        <div class="selector">
            <div class="opcion">
                <router-link class="btn custom-button-busqueda" :to="{ name: 'catalogoview' }"
                    style="text-decoration:none;" @click="closeList">
                    <i class="bx bx-search-alt"></i>&nbsp;
                    Busqueda
                </router-link>
            </div>
            <div class="linea"></div>
            <!--
                                <div class="opcion">
                <h4>Año: </h4>
              
                <select v-model="selectedYear">
                    <option v-for="year in lineasBsuqe" :value="year">
                        <router-link :to="{ name: 'catalogoview', params: { year: year.year } }"
                            style="text-decoration:none; font-size:0.75rem;color: #fff;font: monospace;">{{ year.year
                            }}</router-link>
                    </option>
                </select>
            </div>
            
            <div class="linea"></div>
            
                <h4>Lineas: </h4>
                
                <select v-model="selectedMonth">
                    <option v-for="linea in lineasDispo">
                        <router-link :to="{ name: 'catalogoview', params: { year: 0, categoria: linea } }"
                            style="text-decoration:none; font-size:9px;color: #fff;font: monospace;">{{ linea
                            }}</router-link>
                    </option>
                </select>
            </div>
            -->
        </div>
        <!-- Línea divisoria -->

        <!-- Enlaces de Navegación Vertical -->
        <div class="enlaces">
            <router-link to="/Home"
                style="text-decoration:none;  margin-bottom: 12px;font-size:9px;color: #fff;font: monospace;color:white;">
                <i class="bx bx-home"></i>&nbsp;Home
            </router-link>
            <router-link to="/ListBlogs"
                style="text-decoration:none;  margin-bottom: 12px;font-size:9px;color: #fff;font: monospace;color:white;">
                <i class="bx bxl-blogger"></i>&nbsp;Blog
            </router-link>
            <router-link to="/contacto"
                style="text-decoration:none;  margin-bottom: 12px;font-size:9px;color: #fff;font: monospace;color:white;">
                <i class="fa fa-envelope fa-fw"></i>&nbsp;Contacto
            </router-link>
            <!--<router-link to="/ProdsMX"
                style="text-decoration:none;  margin-bottom: 12px;font-size:9px;color: #fff;font: monospace;color:white;">
                <i class="fa fa-map fa-fw"></i>&nbsp;Hecho en México
            </router-link>-->
            <router-link to="/catalogosview"
                style="text-decoration:none;  margin-bottom: 12px;font-size:9px;color: #fff;font: monospace;color:white;">
                <i class="fa fa-regular fa-book fa-fw"></i>&nbsp;Catálogos
            </router-link>
            <router-link to="/OurBrands"
                style="text-decoration:none;  margin-bottom: 12px;font-size:9px;color: #fff;font: monospace;color:white;">
                <i class="fa fa-dot-circle fa-fw"></i>&nbsp;Nuestras Marcas
            </router-link>

            <!-- Agrega más enlaces si es necesario -->
        </div>
    </div>
    <!--<button class="floating-button" @click="toggleMenu">Menú</button>-->
    <div v-if="isLoggedin">
        <button class="bottom-left-button" @click="toggleMenuNuevo">
            <i :class="isOpen ? 'fa fa-times' : 'fa fa-bars'" aria-hidden="true"></i>
        </button>
        <div class="accordion-menu" :style="{ maxHeight: isOpen ? maxHeight : '0px' }">
            <!-- Dashboard -->
            <div class="accordion-item">
                &nbsp;<i class="fa fa-desktop" aria-hidden="true"></i>&nbsp;
                <router-link
                    v-if="rol_auth.includes('admin') || rol_auth.includes('asesor') || rol_auth.includes('vendedor')"
                    to="/AdminDashboard" class="accordion-item-header" style="text-decoration: none;">
                    Dashboard
                </router-link>
                <router-link v-else to="/dashboard" class="accordion-item-header" style="text-decoration: none;">
                    Dashboard
                </router-link>
            </div>

            <!-- Pedidos -->
            <div class="accordion-item" v-if="rol_auth.includes('cliente')">
                <div @click="toggleSubMenu('Pedidos')" class="accordion-item-header">
                    &nbsp;<i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;
                    Pedidos
                </div>
                <div v-if="activeSubMenu === 'Pedidos'" class="accordion-submenu">

                    <router-link v-if="rol_auth.includes('cliente')"
                        :to="{ name: 'listadocotizaciones', params: { cliente: cliente_auth } }"
                        class="accordion-subitem" style="text-decoration: none;">
                        &nbsp;<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;
                        No Autorizados
                    </router-link>

                    <router-link v-if="rol_auth.includes('cliente')"
                        :to="{ name: 'listadocotizacionesaut', params: { cliente: cliente_auth } }"
                        class="accordion-subitem" style="text-decoration: none;">
                        &nbsp;<i class="fa fa-check-circle" aria-hidden="true"></i>&nbsp;
                        Autorizados
                    </router-link>
                </div>
            </div>

            <!-- Cotizador -->
            <div class="accordion-item" v-if="rol_auth.includes('cliente')">
                <router-link to="/HelpCotizador" class="accordion-item-header" style="text-decoration: none;">
                    &nbsp;<i class="fa fa-calculator" aria-hidden="true"></i>&nbsp;
                    Cotizador
                </router-link>
            </div>

            <!-- Productos -->
            <div class="accordion-item" v-if="rol_auth.includes('admin')">
                <div @click="toggleSubMenu('Productos')" class="accordion-item-header">
                    &nbsp;<i class="fa fa-box" aria-hidden="true"></i>&nbsp;
                    Productos
                </div>
                <div v-if="activeSubMenu === 'Productos'" class="accordion-submenu">

                    <router-link to="/Productos" class="accordion-subitem" style="text-decoration: none;">
                        &nbsp;<i class="fa fa-box" aria-hidden="true"></i>&nbsp;
                        Productos
                    </router-link>

                    <router-link to="/actualizarprods" class="accordion-subitem" style="text-decoration: none;">
                        &nbsp;<i class="fa fa-upload" aria-hidden="true"></i>&nbsp;
                        Carga de productos
                    </router-link>

                    <router-link to="/Listayear" class="accordion-subitem" style="text-decoration: none;">
                        &nbsp;<i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;
                        Años
                    </router-link>
                </div>
            </div>

            <!-- Usuarios -->
            <div class="accordion-item" v-if="rol_auth.includes('admin') || permissions_auth.includes('users-read')">
                <div @click="toggleSubMenu('Usuarios')" class="accordion-item-header">
                    &nbsp;<i class="fa fa-users" aria-hidden="true"></i>&nbsp;
                    Usuarios
                </div>
                <div v-if="activeSubMenu === 'Usuarios'" class="accordion-submenu">
                    <router-link to="/users" class="accordion-subitem"
                        v-if="rol_auth.includes('admin') || permissions_auth.includes('users-read')"
                        style="text-decoration: none;">
                        &nbsp;<i class="fa fa-users" aria-hidden="true"></i>&nbsp;
                        Usuarios
                    </router-link>
                    <router-link to="/roles" class="accordion-subitem"
                        v-if="rol_auth.includes('admin') || permissions_auth.includes('roles-read')"
                        style="text-decoration: none;">
                        &nbsp;<i class="fa fa-user-shield" aria-hidden="true"></i>&nbsp;
                        Roles
                    </router-link>
                    <router-link to="/permisos" class="accordion-subitem"
                        v-if="rol_auth.includes('admin') || permissions_auth.includes('permissions-read')"
                        style="text-decoration: none;">
                        &nbsp;<i class="fa fa-lock" aria-hidden="true"></i>&nbsp;
                        Permisos
                    </router-link>
                </div>
            </div>

            <!-- Banner's -->
            <div class="accordion-item"
                v-if="rol_auth.includes('admin') || rol_auth.includes('MKTG') || permissions_auth.includes('publicaciones-read')">
                <router-link to="/posts" class="accordion-item-header" style="text-decoration: none;">
                    &nbsp;<i class="fa fa-image" aria-hidden="true"></i>&nbsp;
                    Banner's
                </router-link>
            </div>

            <!-- Documentos PDFS -->
            <div class="accordion-item"
                v-if="rol_auth.includes('admin') || rol_auth.includes('MKTG') || permissions_auth.includes('pdfcatalogo-create')">
                <router-link to="/documentoindexview" class="accordion-item-header" style="text-decoration: none;">
                    &nbsp;<i class="fa fa-file-pdf" aria-hidden="true"></i>&nbsp;
                    Documentos PDFS
                </router-link>
            </div>

            <!-- Config. Correos -->
            <div class="accordion-item" v-if="rol_auth.includes('admin')">
                <router-link to="/Correos" class="accordion-item-header" style="text-decoration: none;">
                    &nbsp;<i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;
                    Config. Correos
                </router-link>
            </div>

            <!-- Detalles -->
            <div class="accordion-item" v-if="rol_auth.includes('admin')">
                <div @click="toggleSubMenu('Detalles')" class="accordion-item-header">
                    &nbsp;<i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                    Detalles
                </div>
                <div v-if="activeSubMenu === 'Detalles'" class="accordion-submenu">
                    <router-link to="/DetailsErrosCarts" class="accordion-subitem" style="text-decoration: none;">
                        Detalles Carrito
                    </router-link>
                    <router-link to="/DetailsErrosProds" class="accordion-subitem" style="text-decoration: none;">
                        Detalles Producto
                    </router-link>
                </div>
            </div>
            <div class="accordion-item" v-if="rol_auth.includes('admin')">
                <div @click="toggleSubMenu('Tools')" class="accordion-item-header">
                    &nbsp;<i class="bx bx-wrench" aria-hidden="true"></i>&nbsp;
                    Tools
                </div>
                <div v-if="activeSubMenu === 'Tools'" class="accordion-submenu">
                    <router-link to="/Sync" class="accordion-subitem" style="text-decoration: none;">
                        &nbsp;<i class="bx bx-sync" aria-hidden="true"></i>&nbsp;
                        Sync Prods
                    </router-link>
                </div>
            </div>
            <div class="accordion-item">
                <div class="icon" @click="logout" style="color:#06283c;">
                    <span class="accordion-item-header">Salir</span><i class="bx bx-log-out"></i>
                </div>
            </div>

        </div>
    </div>
    <!--
<div class="footer-tab" :class="{ 'expanded': isExpandedFooter }" @click="toggleExpandedFooter">
        <span class="footer-tab-content">2024-03-22</span>
    </div>
    
    <transition name="slide">
        <div v-if="isExpandedFooter" class="footer-expanded-container expanded-below">
            <div class="footer-expanded-content">
                <div class="footer-expanded-inner">
                    <div class="row">
                        <div class="col-md-4">Columna 1</div>
                        <div class="col-md-4">Columna 2</div>
                        <div class="col-md-4">Columna 3</div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    -->
</template>

<script>
import { debounce } from 'lodash';
export default {
    data() {
        return {
            /*isLoggedin: window.Laravel.isLoggedin,*/
            cliente_auth: window.Laravel.user ? window.Laravel.user.id : '',
            namecliente_auth: window.Laravel.user ? window.Laravel.user.name : '',
            rol_auth: window.Laravel.roles,
            permissions_auth: window.Laravel.permissions_auth,
            showList: false,
            searchExpanded: false,
            searching: false,
            inputValue: '',
            nav2: false,
            showSecondRow: false,
            showText: false,
            lineasBsuqe: '',
            lineasDispo: '',
            resultados: [],
            resultados2: [],
            masbuscados: [],
            mejoresVentas: [],
            isMenuOpen: false,
            showProducts: false,
            showUsers: false,
            showPedidos: false,
            isSubMenuOpen: false,
            menuproductos: false,
            sidebarOpenRespo: false,
            showSearchInput: false,
            isExpandedFooter: false,
            isMenuOpenRespo: false,
            showErrors: false,
            selectedYear: '',
            selectedMonth: '',
            showCaptcha: false,
            recaptchaToken: null,
            isOpen: false,
            activeSubMenu: null,

        }
    },
    created() {
        this.handleSearchInputDebounced = debounce(this.handleSearchInput, 100);
    },
    mounted() {
        //console.log('permisos a la hora de cargar')
        //console.log(window.Laravel)
        this.$store.dispatch('obtenerCantidadEnCarrito');
        this.CargaBusquedas()

        window.addEventListener('scroll', this.handleScroll);


        //document.addEventListener('click', this.closeSearchResults);
        //this.cargainicial()
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeSearchResults);
    },
    computed: {
        obtenerCantidadEnCarrito() {
            //console.log(this.$store.getters.obtenerCantidadEnCarrito)
            return this.$store.getters.obtenerCantidadEnCarrito;
        },
        isLoggedin() {
          
          return this.$store.getters.isLoggedin
      },
    },
    watch: {
        isLoggedin(newStatus) {
            if (!newStatus) {
                // Código para manejar cuando el usuario se desloguea
            }
        },
    },
    methods: {
        toggleMenuNuevo() {
            this.isOpen = !this.isOpen;
        },
        toggleSubMenu(name) {
            this.activeSubMenu = this.activeSubMenu === name ? null : name;
        },
        handleSubItemClick() {
            this.isOpen = false;
            this.activeSubMenu = null;
        },
        getLoginUrl() {
            //return '/Ingresa';
            window.location.href = `${window.location.origin}/Ingresa`;
        },
        toggleMenuResponsive() {
            this.sidebarOpenRespo = !this.sidebarOpenRespo;
        },
        toggleExpandedFooter() {
            this.isExpandedFooter = !this.isExpandedFooter; // Cambia el estado de expansión
        },
        handleDashboardClick() {
            // Aquí puedes realizar la acción que desees al hacer clic en el enlace
            this.isMenuOpen = false,
                this.showProducts = false,
                this.showUsers = false,
                this.isSubMenuOpen = false
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        cargainicial() {
            this.busquedarest()
            this.CargaBusquedas()
        },
        getAbsoluteImageUrl(relativeUrl) {
            // Obtén la URL base de Laravel
            const baseUrl = window.location.origin;

            // Concatena la URL base con la ruta relativa de la imagen
            return baseUrl + relativeUrl;
        },
        toggleList2() {
            // Cambiar el estado de showList al hacer clic en el botón
            this.nav2 = !this.nav2;


            // Otros cambios que quieras hacer al hacer clic en el botón
        },
        toggleList() {
            this.showList = !this.showList;
            this.shrinkSearchInput();
            this.llenadatos();
        },
        closeList() {
            this.showList = false;
        },
        expandSearchInput() {
            this.showList = false;
            this.searchExpanded = true;
            this.CargaBusquedas()
            if (this.inputValue != '')
                this.busquedarest()
            else
                this.resultados = []
            this.menuproductos = true;



        },
        async handleSearchInput() {
            this.searching = true;
            this.showSpinnerIcon = true;
            this.showList = false;

            if (this.inputValue.length >= 3) {
                try {
                    const response = await axios.get(`/search/${this.inputValue}`);
                    this.resultados = response.data;
                    this.resultados2 = response.data;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.showSpinnerIcon = false;
                    this.searching = false;
                    this.menuproductos = true;

                }
            } else {
                this.resultados = [];
                this.menuproductos = false;
                this.searching = false;
            }
        },
        /*async handleSearchInput() {
            this.searching = true;
            this.showSpinnerIcon = true;
            this.showList = false;

            if (this.inputValue.length >= 3) {
                try {
                    // Verificar si se necesita mostrar reCAPTCHA
                    if (this.showCaptcha) {
                        // Ejecutar reCAPTCHA y obtener el token
                        this.recaptchaToken = await this.$recaptcha.execute('search');
                    }

                    // Preparar parámetros para la solicitud
                    const params = {
                        query: this.inputValue,
                    };

                    // Agregar token de reCAPTCHA si está disponible
                    if (this.recaptchaToken) {
                        params['g-recaptcha-response'] = this.recaptchaToken;
                    }

                    // Realizar la solicitud GET a la API de búsqueda
                    const response = await axios.get(`/search/${this.inputValue}`, { params });

                    // Manejar la respuesta exitosa de la búsqueda
                    this.resultados = response.data;
                    this.showCaptcha = false; // Resetear la necesidad de reCAPTCHA
                    this.recaptchaToken = null; // Limpiar el token de reCAPTCHA
                } catch (error) {
                    // Manejar errores de la solicitud
                    if (error.response && error.response.status === 429 && error.response.data.message === 'Captcha required') {
                        this.showCaptcha = true; // Mostrar reCAPTCHA si es requerido
                    } else {
                        console.error(error); // Loggear otros errores en la consola
                    }
                } finally {
                    // Finalizar el proceso de búsqueda (limpiar estados de carga)
                    this.showSpinnerIcon = false;
                    this.searching = false;
                    this.menuproductos = true; // Mostrar menú de productos si hay resultados
                }
            } else {
                // Limpiar resultados y desactivar menú si la entrada es menor de 3 caracteres
                this.resultados = [];
                this.menuproductos = false;
                this.searching = false;
            }
        },*/
        shrinkSearchInput() {
            this.searchExpanded = false;

        },
        clearSearchText() {
            this.inputValue = ''; // Borrar el texto del input
            this.shrinkSearchInput();
        },
        handleScroll() {
            // Lógica para mostrar/ocultar la segunda fila según el scroll
            if (window.scrollY > 100) {
                this.showSecondRow = true;
            } else {
                this.showSecondRow = false;
            }
        },
        hideResults() {
            // Ocultar resultados solo si ni el input ni los resultados están siendo enfocados
            if (!this.inputFocused && !this.resultsFocused) {
                this.menuproductos = false;
            }
        },
        llenadatos() {

            axios.get(`/catalogo/list`)
                .then((response) => {
                    console.log(response.data)
                    this.lineasBsuqe = response.data.listyear;
                    this.lineasDispo = response.data.lineas;
                    //console.log(this.lineasDispo)
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        CargaBusquedas() {
            axios.get(`/api/home/buscados`)
                .then((response) => {
                    //this.showSpinnerIcon = false;
                    console.log(response)
                    this.masbuscados = response.data.masbuscados;
                    this.mejoresVentas = response.data.mejoresVentas;
                });
        },
        async busquedarest() {
            if (this.inputValue.length >= 3) {
                try {
                    this.isSearching = true; // Indicar que se está realizando una búsqueda

                    // Esperar a que se complete la búsqueda
                    await axios.get(`/search/${this.inputValue}`)
                        .then((response) => {
                            this.showSpinnerIcon = false;
                            this.resultados = response.data;
                            this.resultados2 = response.data;
                        });

                } catch (error) {
                    console.error(error);
                } finally {
                    this.isSearching = false; // Indicar que la búsqueda ha terminado
                }
            }
        },

        async busquedaEnter() {
            if (this.inputValue.length >= 3) {
                if (!this.searching) {
                    await this.handleSearchInput(); // Esperar a que handleSearchInput termine
                    this.menuproductos = false;
                    this.resultados = [];
                    this.masbuscados = [];
                    this.mejoresVentas = [];

                    const prods = this.inputValue;
                    this.inputValue = '';

                    this.$router.push({ name: 'ListadoProductos', params: { prod: prods } });
                }
            }
        },
        handleItemClick() {
            // Do something with the clicked item (e.g., navigate to the details page)
            // Close the search results container
            this.inputValue = '';
            this.resultados = [];
            this.masbuscados = [];
            this.mejoresVentas = [];
        },
        ajusteclick() {

            // Ejecutar acciones solo si no se hizo clic en un <router-link>
            this.searchExpanded = false;
            this.inputValue = '';
            this.resultados = [];

        },
        closeSearchResults(event) {
            // Verificar si se hizo clic dentro del área del input de búsqueda o en la lista de resultados
            const searchInput = document.getElementById('inputsearch');
            const searchResults = document.getElementById('divresultados');
            if (searchInput.contains(event.target) || searchResults.contains(event.target)) {
                return;
            }

            // Si se hizo clic fuera del área del input de búsqueda y de los resultados de búsqueda, cerrar la lista de resultados
            this.inputValue = '';
            this.resultados = [];
            // Aquí puedes agregar otras acciones si es necesario
        },
        logout() {
            //axios.post('logout')
            axios.post(`${window.location.origin}/logout`)
                .then(response => {
                    // Manejar la respuesta si es necesario
                    //console.log('Logout exitoso');
                    // Actualizar window.Laravel para indicar que el usuario no está autenticado

                    window.location.href = `${window.location.origin}/home`;
                })
                .catch(error => {
                    // Manejar errores si es necesario
                    console.error('Error al hacer logout', error);
                });
        },
        handleBlur() {
            this.menuproductos = false
            this.isSearching = false
            this.searchExpanded = false
        },
        toggleSidebarRespo() {
            console.log(this.sidebarOpenRespo)
            this.sidebarOpenRespo = !this.sidebarOpenRespo;
        }, toggleSearchInput() {
            this.showSearchInput = !this.showSearchInput;
        },
        verProducto(id) {
            // Realiza cualquier lógica adicional aquí, si es necesario
            // Por ejemplo, puedes validar algo antes de navegar a la página del producto

            // Luego, navega a la página del producto utilizando router.push()
            this.$router.push({ name: 'verprods', params: { id: id } });
            this.menuproductos = false
        },
        handleResultClick(resultado) {
            // Vaciar el valor del input
            this.inputValue = '';
            this.searchExpanded = false;




            // Limpiar los resultados de búsqueda
            this.resultados = [];
            // Cerrar el menú de productos
            this.menuproductos = false;
            // Llamar a la función verProducto
            this.verProducto(resultado.k_parte);
        },

    }
}
</script>

<style scoped>
@import url('https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css');

.container {
    display: flex;
}

.toggle-button-medium {
    display: none;
    /* Oculta la barra fija en pantallas pequeñas */
}

.logo-img {
    width: 200px;
    /* Tamaño inicial */
    transition: width 0.3s ease;
    /* Transición suave de tamaño */
}

.logo-img-smaller {
    width: 75px;
    /* Tamaño más pequeño */
    transition: width 0.3s ease;
    /* Transición suave de tamaño */
}

.navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    color: #333;
    padding: 10px 20px;
    margin-bottom: 10%;
    z-index: 9999;

    /* Otros estilos */
}

.navbarBlack {
    /*background-color: #333;*/
    background: rgba(6, 40, 60, 0.8);
    color: white;
}

.navbar-container {
    position: relative;
}
.cart {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-top {
    margin-bottom: 5px;
}

.cliente-info {
    color: #ff9900;
    font-size: 14px; /* Puedes ajustar el tamaño según prefieras */
}


/* Estilos para la barra de navegación responsiva */
.navbar-responsive {
    display: none;
    /* Por defecto oculta la barra responsiva */
    /* Otros estilos */
}

.icon.active {
    color: #06283c;
    /* Cambia el color del icono cuando el menú está abierto */
}

.menu-text.active {
    color: #06283c;
    /* Cambia el color del texto cuando el menú está abierto */
}

.sidebar {
    position: fixed;
    top: 30%;
    left: 0;
    min-width: 5%;
    width: auto;
    /* Ancho inicial del menú */
    height: auto;
    background-color: white;

    border: 1px solid #06283c;
    transition: width 0.3s ease;
    z-index: 9999;

}

.sidebar.open {
    width: 200px;
    /* Ancho del sidebar cuando el menú está abierto */
    height: 40%;
}

.sidebar.sub-menu-open {
    height: 60%;
    /* Ajusta el ancho según sea necesario */
    /* Otros estilos que desees aplicar cuando se abre un submenú */
}

.sidebar-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    /* Espacio superior */
}

.icon {
    margin-bottom: 20px;
    /* Espacio entre íconos */
    cursor: pointer;
}

.menu-items {
    padding-top: 5px;
    /* Espacio superior */
}

.bottom-left-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 50px;
    /* Ajusta según el tamaño deseado */
    height: 50px;
    /* Ajusta según el tamaño deseado */
    background-color: #06283c;
    color: #ff9900;
    border: none;
    border-radius: 50%;
    /* Hace el botón redondo */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    /* Ajusta el tamaño del ícono */
    cursor: pointer;
    z-index: 999999;
}

.bottom-left-button i {
    font-size: 24px;
    /* Ajusta el tamaño del ícono */
}

.bottom-left-button:hover {
    background-color: #06283c;
}

.accordion-menu {
    position: fixed;
    bottom: 70px;
    width: auto;
    /* Adjusted to appear above the button */
    left: 20px;
    background-color: #ff9900;
    border-radius: 5px;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    z-index: 999999;
    transform-origin: bottom left;
}

.accordion-item {
    color: white;
    cursor: pointer;

    font-size: 16px;

}

.accordion-item-header {

    font-size: 16px;
    color: #06283c;
}

.accordion-item-header:hover {
    background-color: #ff8c00;
}

.accordion-submenu {
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    padding-left: 20px;
    background-color: #ff9900;
}

.accordion-subitem {
    padding: 10px;
    font-size: 14px;
    color: #06283c;
}

.accordion-subitem:hover {
    background-color: #ff8c00;
}

.option {
    align-items: center;
    padding: 10px;
    cursor: pointer;
    color: #06283c;
}

.option i {
    margin-right: 10px;
}

.option span {
    display: inline-block;
}

.sub-options {

    color: #1693dc;
    /* Indentación de las subopciones */
}

.sub-option {
    padding: 5px 10px;
    cursor: pointer;
}

/* Estilos para íconos chevron (flechas hacia arriba y hacia abajo) */
.bx-chevron-up:before,
.bx-chevron-down:before {
    content: "\f106";
    /* Código de ícono para flecha hacia arriba */
}

.bx-chevron-down:before {
    content: "\f107";
    /* Código de ícono para flecha hacia abajo */
}

.floating-button {
    position: absolute;
    top: 20%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.icon-link {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    color: white;
}

.icon-link a {
    text-decoration: none;
    color: inherit;
}

.sub-menu {
    list-style-type: none;
    padding-left: 0;
}

.sub-menu li {
    padding: 10px;
}

.sub-menu li a {
    text-decoration: none;
    color: white;
}

.arrow {
    margin-left: auto;
}

.link_name {
    display: none;
}

.active .link_name {
    display: block;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}



.navbar-right {
    display: flex;
    align-items: center;
}

.btn-toggle {
    background-color: #ff9900;
    padding: 5px 5px;
    font-weight: bold;
    border: none;
    color: #06283c;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
    /* Hace el botón ovalado */
    width: auto;
    /* Ancho del botón */
    height: 40px;
    /* Altura del botón */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    /* Transición suave del color de fondo */
}

.btn-toggle.active {
    background-color: #06283c;
    /* Cambia el color de fondo cuando el botón está activo */
    color: white;
    /* Cambia el color del texto cuando el botón está activo */
}



.btn-toggle:hover {
    background-color: #ff9900;
    /* Cambia el color de fondo al pasar el cursor sobre el botón */
}

.category-list {
    position: absolute;
    left: 13%;
    top: 50%;
    background: rgba(6, 40, 60, 0.8);
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    width: 20%;
    z-index: 99999;
}

.category-list.active {
    top: 55%;
    left: 10%;
}

/*
.category-list {
    position: absolute;
    top: 55%;
    left: 22%;
    background-color: #06283c;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    width: 20%;
    z-index: 99999;
}
*/
.category-list2 {
    position: absolute;
    background-color: #ced7dc;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    width: auto;
    z-index: 99999;
}

.category-list.show {
    display: block;
}

.category-list2.show {
    display: block;
}


.category-container h6 {
    margin-bottom: 10px;
    /* Agrega margen inferior entre el título y la lista de categorías */
}

.column1 {
    float: left;
    width: 30%;
}

.column2 {
    float: left;
    width: 60%;
}

.column ul {
    list-style-type: none;
    color: #333;
    padding: 0;
    overflow-y: auto;
}

.search {
    position: relative;
}

.input-container {
    position: relative;
}

.search-wrapper {
    position: relative;
}

.search-input {
    padding: 10px 10px 10px 30px;
    /* Ajustar el padding para dejar espacio al icono */
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    transition: width 0.3s ease-in-out;
}

.search-input.expanded {
    width: 100%;
    /* Ancho expandido */
}

.search-icon-container {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Centra horizontalmente */
    text-align: center;
    /* Alinea el texto horizontalmente */
    color: #06283c;
    margin-bottom: 1%;
}

.clear-icon {
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translateY(-50%);
    background-color: white;
    /* Cambia el color de fondo cuando el botón está activo */
    color: black;
}

.cart {
    margin-left: 10px;
}

.nav-link-container {
    width: auto;
    height: auto;
    /* Ajusta el ancho del contenedor según sea necesario */
    position: relative;

}

/* Estilos para los enlaces */
.nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1%;

    color: #06283c;
    /* Elimina el subrayado del enlace */
}

.nav-link2 {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    padding: 1%;
    /* Elimina el subrayado del enlace */
}

.nav-link i {
    margin-right: 5px;
    /* Ajusta el margen entre el icono y el texto */
}

/* Estilos para el texto */
.text {
    opacity: 0;
    /* Por defecto, el texto está oculto */
    transition: opacity 0.3s ease;
    /* Transición suave */

    /* Evita que el texto afecte el comportamiento del enlace */
}



::-webkit-scrollbar {
    width: 7px;
    /* Ancho del scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: #ff9900;
    /* Color del thumb (barra de desplazamiento) */
    border-radius: 5px;
    /* Radio de borde del thumb */
}

::-webkit-scrollbar-track {
    background-color: #fff;
    /* Color del track (fondo del scrollbar) */
}

/*resultados */
.result-container {
    position: absolute;
    top: 97%;
    /* Posiciona los resultados debajo del input */
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;

}

.result-container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.result-container li {
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

.result-container li:last-child {
    border-bottom: none;
}

.navbar-tablet {
    display: none;
    /* Se oculta en pantallas pequeñas (teléfono) */
}

.navbar-mobile {
    display: none;
    /* Se oculta en pantallas grandes (tablet y escritorio) */
}

.custom-button-busqueda {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    /* Color del texto */
    background-color: #06283c;
    /* Color del fondo */
    border: none;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
    /* Transiciones suaves para el hover */
}

.custom-button-busqueda:hover {
    background-color: #ff9900;
    /* Color del fondo al hacer hover */
    color: #fff;
    /* Color del texto al hacer hover */
}

@media (max-width: 768px) {
    .toggle-button-medium {
        display: block;
        /* Oculta la barra fija en pantallas pequeñas */
    }

    .btn-toggle {
        display: none;
        /* Oculta la barra fija en pantallas pequeñas */
    }

    .btn-toggle {
        display: none;
        /* Oculta la barra fija en pantallas pequeñas */
    }

    .navbar-second-row {
        display: none !important;
        /* Oculta la barra fija en pantallas pequeñas */
    }

    .navbar-responsive {
        position: fixed;
        display: flex;
        width: 100%;
        height: 5%;
        align-items: center;
        /* Centra verticalmente los elementos */
        justify-content: center;
        /* Centra horizontalmente los elementos */
        background: rgba(6, 40, 60, 0.8);
        /* Muestra la barra responsiva en pantallas pequeñas */
    }

    .logo {
        width: 30%;
        height: 1%;
        margin-left: 10%;
        /* Ajusta el tamaño del contenedor del logo */
        text-align: center;
        /* Centra horizontalmente la imagen */
    }

    .search-input {
        padding: 10px 10px 10px 30px;

        border-radius: 5px;
        width: 100%;
        transition: width 0.3s ease-in-out;
    }

    .search-input.expanded {
        width: 300%;
        /* Ancho expandido */
    }

}

.sidebarRespo {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 200px;
    /* Ancho del menú lateral */
    background: rgba(6, 40, 60, 0.8);
    color: #fff;
    z-index: 9999;
}

.sidebarRespo.active {
    background-color: red;
    /* Cambiamos el color de fondo temporalmente para verificar si se muestra */
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(0);
    /* Cambia la posición inicial según tu diseño */
}

@media (max-width: 560px) {
    .toggle-button-medium {
        display: none;
        /* Oculta la barra fija en pantallas pequeñas */
    }

    .footer-tab {
        display: none;
    }

    .navbar-fixed {
        padding: 0;
    }

    .navbar-tablet {
        display: none;
        /* Se oculta en pantallas pequeñas (teléfono) */
    }

    .navbar-container {
        display: none;
        /* Oculta la barra fija en pantallas pequeñas */
    }


    .navbar-mobile {
        width: 100%;
        display: flex;
        /* Se muestra solo en pantallas pequeñas (teléfonos) */
        justify-content: space-between;
        /* Distribuye los botones horizontalmente */
        align-items: center;
        padding: 10px;
        /* Centra los elementos verticalmente */
        background: rgba(6, 40, 60, 0.8);
        color: white;
        height: 10%;

    }

    .logo-container {
        text-align: center;
    }

    .toggle-button {
        cursor: pointer;
        margin-left: 10%;
    }

    .toggle-icon {
        width: 30px;
        height: 5px;

        border-radius: 3px;
    }

    .menu {
        position: fixed;
        top: 0;
        right: -200px;
        /* Inicialmente oculto hacia la derecha */
        width: 200px;
        height: 100vh;
        /* Toma el 100% del alto de la ventana */
        background-color: #fff;
        /* Color de fondo del menú */
        transition: transform 0.3s ease;
        /* Transición suave al desplegar/ocultar */
    }

    .menu-open {
        transform: translateX(-200px);
        /* Desplaza el menú hacia la izquierda para mostrarlo */
    }


    .icons {
        display: flex;
        color: white;
    }

    /* Estilos para los iconos */
    .icons i {
        font-size: 12px;
        margin: 0 10px;
    }
}

.footer-tab {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #06283c;
    color: white;
    padding: 10px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    z-index: 999;
}

.footer-tab.expanded {
    bottom: calc(60px + 10px);
    /* Distancia desde la parte inferior de la pestaña más margen superior */
}


.footer-expanded-container {
    position: fixed;
    bottom: 0;
    /* Distancia desde la parte inferior de la pestaña más margen superior */

    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 998;
    /* Ancho máximo del contenido */
    padding: 10px;
    background-color: #06283c;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}



.footer-expanded-inner {
    max-height: 300px;
    /* Altura máxima del contenido */
    overflow-y: auto;
}

.row {
    display: flex;
    justify-content: space-between;
}

.col-md-4 {
    flex: 0 0 calc(33.33% - 20px);
    /* Espacio entre columnas */
    max-width: calc(33.33% - 20px);
    /* Ancho máximo de las columnas */
    padding: 10px;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(100%);
}

.expanded-below {
    transform: translateY(-100%);
    /* Desplaza el contenido hacia abajo */
}

.selector {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

/* Estilos para cada opción dentro del selector */
.opcion {
    margin-bottom: 10px;
    color: white;
}

/* Estilos para el separador entre opciones */
.linea {
    border-top: 1px solid #ccc;
    margin: 10px 0;
    color: white;
}

/* Estilos para el contenedor de los enlaces */
.enlaces {
    padding: 10px;

    color: white;
    display: flex;
    flex-direction: column;
}
</style>
