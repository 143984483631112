
import { createWebHistory, createRouter } from "vue-router";
import axios from 'axios';
export const routes = [
    {
        path: '/',
        redirect: '/home', // Redirigir la raíz a /home
    },
    {
        name: 'home',
        path: '/home',
        component: () => import('../pages/home'),
        meta: { title: 'Bienvenido', requiresAuth: false },
    },
    {
        name: 'users',
        path: '/users',
        component: () => import('../components/Users'),
        meta: { title: 'Admin. Usuarios', requiresAuth: true, roles: ['admin'] , permisos: ['users-read']},
    },
    {
        name: 'register',
        path: '/register',
        component: () => import('../pages/Register'),
        meta: { title: 'Nuevo User', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'Ingresa',
        path: '/Ingresa',
        component: () => import('../pages/Login'),
        meta: { title: 'Inicia Sesión' },
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('../components/DashboardComponent'),
        meta: { title: 'Escritorio', requiresAuth: true, roles: ['cliente'] },
    },
    {
        name: 'contacto',
        path: '/Contacto',
        component: () => import('../components/navegation/ContactoEmpresa'),
        meta: { title: 'Contacto' }
    },
    {
        name: 'ProdsMX',
        path: '/ProdsMX',
        component: () => import('../components/navegation/prodmx'),
        meta: { title: 'Hechos en México' }
    },
    {
        name: 'OurBrands',
        path: '/OurBrands',
        component: () => import('../components/navegation/nuestrasmarcas'),
        meta: { title: 'Nuestras Marcas' }
    },
    {
        name: 'posts',
        path: '/posts',
        component: () => import('../components/Posts'),
        meta: { title: 'Admin. Banners', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'addpost',
        path: '/posts/add',
        component: () => import('../components/AddPost'),
        meta: { title: 'Crear Banner', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'editpost',
        path: '/posts/edit/:id',
        component: () => import('../components/EditPost'),
        meta: { title: 'Editar Banner', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'roles',
        path: '/roles',
        component: () => import('../Admin/roles/Index'),
        meta: { title: 'Index Roles', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'permisos',
        path: '/permisos',
        component: () => import('../Admin/permissions/index'),
        meta: { title: 'Index Permisos', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'editpermiso',
        path: '/permisos/edit/:id',
        component: () => import('../Admin/permissions/Edit'),
        meta: { title: 'Edit Permisos', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'addPermisos',
        path: '/permisos/add',
        component: () => import('../Admin/permissions/create'),
        meta: { title: 'Add Permiso', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'addRole',
        path: '/roles/add',
        component: () => import('../Admin/roles/AddRole'),
        meta: { title: 'Add Rol', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'editrole',
        path: '/roles/edit/:id',
        component: () => import('../Admin/roles/EditRole'),
        meta: { title: 'Editar Rol', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'edituser',
        path: '/users/getPermissions/:id',
        component: () => import('../components/EditUser'),
        meta: { title: 'Editar Usuario', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'marcas',
        path: '/marcas',
        component: () => import('../comunidad/marcas'),
        meta: { title: 'Nuestras Marcas' }
    },
    {
        name: 'excel',
        path: '/actualizarprods',
        component: () => import('../components/UploadExcel/CargaProds'),
        meta: { title: 'Carga Productos', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'verprods',
        path: '/verprods/:id',
        component: () => import('../components/Prods/vistaProds2'),
        meta: { title: 'Detalle Producto' }

    },
    {
        name: 'Listayear',
        path: '/Listayear',
        component: () => import('../components/Prods/adminprods'),
        meta: { title: 'Admin Producto', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'addmarca',
        path: '/marca/add',
        component: () => import('../components/Prods/addMarca'),
        meta: { title: 'Agregar Marca', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'ListadoProds',
        path: '/ListaProds',
        component: () => import('../Admin/Productos/Productos'),
        meta: { title: 'Productos' }
    },
    {
        name: 'ListadoProductos',
        path: '/ListadoProductos/:prod',
        component: () => import('../components/viewsCart/ProductCard2'),
        meta: { title: 'Resultados' }
    },
    {
        name: 'CatalogoListaProductos',
        path: '/catalogo/busqueda/:year/:marca/:modelo/:motor',
        component: () => import('../components/Prods/catalogoprods'),
        meta: { title: 'Busqueda por Catalogo' }
    },
    {
        name: 'listadocotizaciones',
        path: '/listadocotizaciones/:cliente',
        component: () => import('../components/navegation/userpedidos'),
        meta: { title: 'List. Ped. No Auth.', requiresAuth: true, roles: ['cliente'] },
    },
    {
        name: 'listadocotizacionesaut',
        path: '/listadocotizacionesaut/:cliente',
        component: () => import('../components/navegation/userpedidos2'),
        meta: { title: 'List. Ped. Auth.', requiresAuth: true, roles: ['cliente'] },
    },
    {
        name: 'CartItemsList',
        path: '/CartItemsList',
        component: () => import('../components/Prods/ItemsCartList3'),
        //meta: { requiresAuth: true , roles: ['cliente'] },
        meta: { title: 'List. Prods. Cart.', requiresAuth: true },
    },
    {
        name: 'admindashboard',
        path: '/AdminDashboard',
        component: () => import('../Admin/dashboardAdmin'),
        meta: { title: 'Dashboard Admin', requiresAuth: true, roles: ['admin'], permisos: ['users-read', 'publicaciones-read'] },
    },
    {
        name: 'indicadores',
        path: '/Indicadores',
        component: () => import('../asesores/indicadores'),
        meta: { requiresAuth: true },
    }
    /*,
    {
        name: 'catalogoview',
        path: '/catalogoview/:year?/:categoria?',
        component: () => import('../components/viewsCart/Catalogo'),

    }*/,
    {
        name: 'documentoindexview',
        path: '/documentoindexview',
        component: () => import('../components/DocumentosIndex'),
        meta: { requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'documentocreateview',
        path: '/documentocreateview',
        component: () => import('../components/DocumentosCreate'),
        meta: { requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'documentoeditview',
        path: '/documentoeditview/:id',
        component: () => import('../components/DocumentosEdit'),
        meta: { requiresAuth: true, roles: ['admin'] },
    },

    {
        name: 'catalogosview',
        path: '/catalogosview',
        component: () => import('../components/Catalogos'),
        meta: { title: 'Pdf Catalogos' }
    },

    {
        name: 'catalogoview',
        path: '/catalogoview/:year?/:categoria?',
        component: () => import('../components/viewsCart/CatalogoAvance'),
        meta: { title: 'Busquedas' }
    },
    {
        name: 'contacto',
        path: '/Contacto',
        component: () => import('../components/navegation/ContactoEmpresa'),
        meta: { title: 'Contactanos' }

    },
    {
        name: 'ProdsMX',
        path: '/ProdsMX',
        component: () => import('../components/navegation/prodmx'),
        meta: { title: 'Productos Mexicanos' }
    },
    {
        name: 'OurBrands',
        path: '/OurBrands',
        component: () => import('../components/navegation/nuestrasmarcas'),
        meta: { title: 'Nuestras Marcas' }
    },
    {
        name: 'RelMarcaLinea',
        path: '/RelMarcaLinea/:operacion',
        component: () => import('../Admin/Productos/RelLinMar'),
        meta: { title: 'Relaciones Categorias', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'UploadFileCar',
        path: '/UploadFileCar',
        component: () => import('../components/viewsCart/UploadFileCar'),
        meta: { title: 'Carga Archivo Cart', requiresAuth: true, },
    },
    {
        name: 'DetallePrepedido',
        path: '/DetallePrepedido',
        component: () => import('../components/navegation/Cotizaciones/DetallCot'),
        meta: { title: 'Aprobación Prepedido', requiresAuth: true },

    },
    {
        name: 'DetallePedidoCreado',
        path: '/DetallePedidoCreado/:sucursal/:folio',
        component: () => import('../components/navegation/Cotizaciones/DetallePedidoCreado'),
        meta: { title: 'Confirmación Prepedido', requiresAuth: true },
    },
    {
        name: 'DetallePrepedidoAuth',
        path: '/DetallePrepedidoAuth/:sucursal/:folio',
        component: () => import('../components/navegation/Cotizaciones/DetallePedidoAuth'),
        meta: { title: 'Confirmación Prepedido', requiresAuth: true },
    },
    {
        name: 'Publicaciones',
        path: '/Publicaciones/:id?',
        component: () => import('../components/Documentos/PublicacionForm'),
        meta: { requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'ListBolletin',
        path: '/ListBolletin',
        component: () => import('../comunidad/ListBolletin'),
        meta: { title: 'Boletínes' }

    },
    {
        name: 'EditProds',
        path: '/EditProds/:id',
        component: () => import('../Admin/configuraciones/EditProds'),
        meta: { requiresAuth: true, roles: ['admin'] },
        meta: { title: 'Editar Producto' }
    },
    {
        name: 'CreateProd',
        path: '/CreateProd',
        component: () => import('../Admin/configuraciones/CrateProd'),
        meta: { title: 'Creación Producto', requiresAuth: true, roles: ['admin'] },
    },
    {
        name: 'Productos',
        path: '/Productos',
        component: () => import('../Admin/configuraciones/viewprods'),
        meta: { requiresAuth: true },
    },
    {
        name: 'ListPublicaciones',
        path: '/ListPublicaciones',
        component: () => import('../comunidad/ListBolletinPublicaciones'),
        meta: { title: 'Index Publicaciónes', requiresAuth: true, roles: ['admin'] },
    }
    ,
    {
        name: 'ListPublicacionesEdit',
        path: '/ListPublicacionesEdit',
        component: () => import('../comunidad/ListBolletinPublicacionesEdit'),
        meta: { title: 'Editar Publicación', requiresAuth: true, roles: ['admin'] },
    }
    ,
    {
        name: 'ListBlogs',
        path: '/ListBlogs',
        component: () => import('../comunidad/ListPublicacionesClientes'),
        meta: { title: 'Publicaciónes' }
    },
    //errores
    {
        name: 'unauthorized',
        path: '/unauthorized',
        component: () => import('../Admin/Errores/unauthorized'),
        meta: { title: 'No autorizado' }
    },
    //vista de mobil
    {
        name: 'mobilsearch',
        path: '/mobilsearch',
        component: () => import('../components/navegation/Mobil/mobilsearch'),
        meta: { title: 'Busqueda Movil' }

    }
    ,
    {
        name: 'Correos',
        path: '/Correos',
        component: () => import('../Admin/configuraciones/configuracionescorreo'),
        meta: { title: 'Config Correos', requiresAuth: true, roles: ['admin'] },

    },
    {
        name: 'DetailsErrosProds',
        path: '/DetailsErrosProds',
        component: () => import('../Admin/Errores/adminerrorsproducto'),
        meta: { title: 'List. Error Prods.', requiresAuth: true, roles: ['admin'] },
    }
    ,
    {
        name: 'DetailsErrosCarts',
        path: '/DetailsErrosCarts',
        component: () => import('../Admin/Errores/adminerrorscart'),
        meta: { title: 'List. Error Cart', requiresAuth: true, roles: ['admin'] },
    }
    ,
    {
        name: 'HerramientasClienteCotizador',
        path: '/HelpCotizador',
        component: () => import('../HelpUsers/HelpCotizador'),
        meta: { title: 'Cotizador', requiresAuth: true, roles: ['cliente'] },
    },
    {
        path: '/:catchAll(.*)', // Ruta de captura para cualquier ruta no definida
        redirect: '/' // Redirige a la ruta de inicio (Home)
    }
    /*,ProdsMX,OurBrands
    {PruebasProds
        name: 'permissions',
        path: '/posts',
        component: Posts
    }*/

];



const router = createRouter({
    history: createWebHistory(),
    base: '/JACVE/',
    routes: routes,
});


router.beforeEach((to, from, next) => {
    const isAuthenticated = window.Laravel.isLoggedin || false;
    //const token = localStorage.getItem('accessToken');
    console.log('asi llegan permisos a la ruta')
    console.log(window.Laravel)
    const userRoles = window.Laravel.roles || [];
    const userPermisos = window.Laravel.permissions || [];

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            //console.log('aqui puede estar el error')
            window.location.href = `${window.location.origin}/Ingresa`;
            //`${window.location.origin}/ingresar`;
        } else {
            const requiredRoles = to.meta.roles || [];
            const requiredPermisos = to.meta.permisos || [];
            const hasRequiredRole = requiredRoles.length === 0 || userRoles.some(role => requiredRoles.includes(role));
            const hasRequiredPermisos = requiredPermisos.length === 0 || userPermisos.some(permiso => requiredPermisos.includes(permiso));
            console.log('permisos rquiere')
            console.log(hasRequiredPermisos)
            console.log('permisos rquiere2')
            console.log(userPermisos)
            if (!hasRequiredRole && !hasRequiredPermisos) {
                //console.log('Usuario no tiene los roles o permisos requeridos');
                next({ name: 'unauthorized' });
            } else {
                //console.log('Usuario autenticado y tiene roles y permisos requeridos');
                if (to.meta && to.meta.title) {
                    document.title = to.meta.title;
                }
                next();
            }
        }
    } else {
        if (to.meta && to.meta.title) {
            document.title = to.meta.title;
        }
        next();
    }
});
export default router;
