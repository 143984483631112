<template>
    <!--otro-->
    <Navbar></Navbar>
    <router-view></router-view>
  
    <Footer></Footer>
</template>

<script>
import Navbar from './components/navegation/Navbar2.vue';
import Footer from './components/navegation/Footer.vue';
export default {
    name: "App",
    components: { Navbar, Footer },
    data() {
        return {
            isLoggedIn: false,
            isMobile: false,
            isStandalone: false,
        }
    },
    created() {
        if (window.Laravel.isLoggedin) {
            this.isLoggedIn = true
        }
        this.isMobile = this.detectMobile();
        this.isStandalone = this.detectStandalone();
        this.requestNotificationPermission();
    },
    methods: {
        detectMobile() {
            return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|BlackBerry/i.test(navigator.userAgent);
        },
        detectStandalone() {
            return (window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone;
        },
        requestNotificationPermission() {
            if ("Notification" in window) {
                Notification.requestPermission().then(permission => {
                    if (permission === "granted") {
                        this.showNotification();
                    }
                });
            }
        },
        showNotification() {
            const notification = new Notification("Hola", {
                body: "¡Bienvenido a nuestra PWA!",
                icon: "/img/icons/android-chrome-192x192.png"
            });
            notification.onclick = () => {
                window.focus();
            };
        },
        logout(e) {
            e.preventDefault()
            this.$axios.get('/sanctum/csrf-cookie').then(response => {
                this.$axios.post('/logout')
                    .then(response => {
                        if (response.data.success) {
                            window.location.href = "/home"
                        } else {
                            console.log(response);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            })
        }
    },
}


</script>

<style scoped>
.content {
    margin-top: 3%;
    height: auto;
    width: auto;
    padding: 3%;
    /* Ajusta según el tamaño del navbar */
    /* Otros estilos */
}

@media (max-width: 1360px) {
    .content {
        margin-top: 5.75%;
        height: auto;
        width: auto;
        /* Ajusta según el tamaño del navbar */
        /* Otros estilos */
    }
}
</style>