import axios from 'axios';

export default {
    state: {
        // departments_test: 0,
        detailscotizaciones: {},
        detailscotizaciones2: {},
    },
    getters: {
        detailscotizaciones(state) {
            return state.detailscotizaciones
        },
        detailscotizaciones2(state) {
            return state.detailscotizaciones2
        },
    },
    mutations: {
        set_cotizaciones: (state, data) => {
            state.detailscotizaciones = data
        },
        set_cotizaciones2: (state, data) => {
            state.detailscotizaciones2 = data
        },
    },
    actions: {

        detailscotizaciones: (context, cliente) => {


            axios.get('/api/controllerpeticiones/cliente/' + cliente + '/listcot'
            ).then((response) => {
                console.log(response.data)
                context.commit('set_cotizaciones', response.data.filtrado)
            }).catch((error) => {
                console.log(error)
            });
        },

        detailscotizacionesprueba: (context, fec) => {
            var today = new Date();
            var pruebafec = fec.fec;
            var pruebacliente = fec.cliente;

            var options = { year: 'numeric', month: 'short', day: 'numeric' };


            var now = today.toLocaleString('en-US', options);
            console.log(now);
            console.log(pruebafec);
            console.log(pruebacliente);

            const fechaComparar = new Date(now).toISOString().split('T')[0];
            console.log(fechaComparar);
            //console.log(cliente);
            if (pruebafec == null || pruebafec === undefined) {
                axios.get('/api/controllerpeticiones/cliente2/' + pruebacliente + '/listped/' + fechaComparar + '   '
                ).then((response) => {
                    console.log(response.data)
                    if (response.data.filtrado.length < 1) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Por favor seleccione una fecha',
                        })
                    }
                    context.commit('set_cotizaciones2', response.data.filtrado)
                }).catch((error) => {
                    console.log(error)
                });
            } else {
                axios.get('/api/controllerpeticiones/cliente2/' + pruebacliente + '/listped/' + pruebafec + '  '
                ).then((response) => {
                    console.log(response.data)
                    if (response.data.filtrado.length < 1) {
                        Swal.fire({
                            title: 'No se encontraron pedidos autorizados',
                            text: 'Por favor seleccione otra fecha',
                            imageUrl: '/images/interrog.gif',
                            imageAlt: 'Custom image',
                        })
                    }
                    context.commit('set_cotizaciones2', response.data.filtrado)
                }).catch((error) => {
                    console.log(error)

                });
            }


        },


    },

}